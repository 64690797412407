export const ACTIVITY_LOGS_FIELD_MAPPING = [
  { field: 'name', value: 'Common Name' },
  { field: 'model__c', value: 'Model' },
  { field: 'description__c', value: 'Description' },
  { field: 'admin_category__c', value: 'Category' },
  { field: 'technical_activity_type__c', value: 'Technical Activity Type' },
  { field: 'funding__c', value: 'Funding' },
  { field: 'industry__c', value: 'Industry Sector' },
  { field: 'technology__c', value: 'Technology Sector' },
  { field: 'project_status__c', value: 'Stage' },
  { field: 'expected_announcement_date__c', value: 'Announcement Date' },
  { field: 'category__c', value: 'Entity Type' },
  { field: 'cii_badge_id__c', value: 'OpenSSF Best Practices Badge ID' },
  { field: 'project_entity_msa_date__c', value: 'Management Services Effective Date' },
  { field: 'policies_url__c', value: 'Policies URL' },
  { field: 'project_entity_name__c', value: 'Formal Name' },
  { field: 'assignment_agreement_url__c', value: 'Assignment Agreement URL' },
  { field: 'parent_entity_relationship__c', value: 'Legal Parent' },
  { field: 'project_entity_msa_expiration_date__c', value: 'Management Services Expiration Date' },
  { field: 'notice_date', value: 'Notice Date' },
  { field: 'start_date__c', value: 'Formation Date' },
  { field: 'patent_license', value: 'Patent License' },
  { field: 'parent_project__c', value: 'Parent Project' },
  { field: 'project_entity_dissolution_date__c', value: 'Dissolution Date' },
  { field: 'charterurl__c', value: 'Charter URL' },
  { field: 'linkedin__c', value: 'LinkedIn URL' },
  { field: 'brand_color__c', value: 'Brand Color' },
  { field: 'facebook__c', value: 'Facebook URL' },
  { field: 'twitter__c', value: 'Twitter URL' },
  { field: 'website__c', value: 'Primary Website URL' },
  { field: 'youtube__c', value: 'YouTube URL' },
  { field: 'pinterest__c', value: 'Pinterest URL' },
  { field: 'project_logo__c', value: 'Project Logo URL' },
  { field: 'notifications_group__c', value: 'Notifications Group' },
  { field: 'reddit__c', value: 'Reddit URL' },
  { field: 'program_manager__c', value: 'Program Manager' },
  { field: 'executive_director__c', value: 'Executive Director' }
];

export const COMMITTEE_LOGS_FIELD_MAPPING = [
  { field: 'collaboration_name__c', value: 'Committee Id' },
  { field: 'contactemail__c', value: 'contact Email' },
  { field: 'type__c', value: 'Type' },
  { field: 'status__c', value: 'Status' },
  { field: 'sfid', value: 'SFID' },
  { field: 'voting_status__c', value: 'Voting Status' },
  { field: 'role__c', value: 'Role' },
  { field: 'account__c', value: 'Account Id' },
  { field: 'appointed_by__c', value: 'Appointed By' },
  { field: 'contact_name__c', value: 'Contact Id' },
  { field: 'business_email_required__c', value: 'Business Email Required' },
  { field: 'mailing_list__c', value: 'Committee Name' },
  { field: 'description__c', value: 'Description' },
  { field: 'enable_voting__c', value: 'Enable Voting' },
  { field: 'project_name__c', value: 'Project Id' },
  { field: 'votind_start_date__c', value: 'voting Start Date' },
  { field: 'end_date__c', value: 'End Date' },
  { field: 'committee_website__c', value: 'Committee Website' },
  { field: 'sso_group_enabled', value: 'SSO Group Enabled' },
  { field: 'lastmodifieddate', value: 'Last Modified Date' },
  { field: 'start_date__c', value: 'Start Date' },
  { field: 'Voting_end_date__c', value: 'Voting End Date' },
  { field: 'project_name', value: 'Project Name' },
  { field: 'committee_name', value: 'Committee Name' },
  { field: 'sfid_legacy', value: 'SFID Legacy' },
  { field: 'member_name', value: 'Member Name' },
  { field: 'voting_end_date__c', value: 'Voting End Date' },
  { field: 'account_name', value: 'Account Name' }
];
