import { CommonModule, DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ExportDataComponent } from '@app/shared/components/export-data/export-data.component';
import { InitialPipe } from '@app/shared/pipes/initial.pipe';
import { ProjectService } from '@app/shared/services/project.service';
import { ACTIVITY_LOGS_FIELD_MAPPING } from '@app/shared/utils/activity-logs';
import { exportToCSV } from '@app/shared/utils/helper';
import { AuditLog, AuditTableData, AuditUser, PaginatedResponse, Project } from 'lfx-pcc';
import { uniqBy } from 'lodash';
import { MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { catchError, Observable, of, take, tap } from 'rxjs';

@Component({
  selector: 'lfx-activity-log',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    InitialPipe,
    MultiSelectModule,
    AvatarModule,
    SkeletonModule,
    TableModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    ExportDataComponent
  ],
  providers: [DatePipe],
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  public project$: Observable<Project | null>;
  public auditLogs$: Observable<PaginatedResponse<AuditLog[]>>;
  public auditLogTableData: AuditTableData[] = [];
  public project: Project;
  public selectedFilter: string = 'All Time';
  public hasError: boolean = false;
  public activityLogMapping: { field: string; value: string }[] = ACTIVITY_LOGS_FIELD_MAPPING;
  // Adjust height of scrollable content to windows height by removing upper section height.
  public tableHeight: string = window.innerHeight - 210 + 'px';
  public recordShowOptions = [
    {
      value: 'Last 7 days',
      label: 'Last 7 days'
    },
    {
      value: 'Last 2 weeks',
      label: 'Last 2 weeks'
    },
    {
      value: 'Last 30 days',
      label: 'Last 30 days'
    },
    {
      value: 'Last 3 months',
      label: 'Last 3 months'
    },
    {
      value: 'All Time',
      label: 'All Time'
    }
  ];
  public filterUsers: AuditUser[] = [];
  public hideFields: string[] = ['systemmodstamp', 'lastmodifieddate', 'lastmodifiedbyid', 'Collaboration_name__c_legacy'];

  private datePipe: DatePipe = inject(DatePipe);
  private projectService: ProjectService = inject(ProjectService);
  private messageService: MessageService = inject(MessageService);
  private dialogService: DialogService = inject(DialogService);

  public ngOnInit(): void {
    this.project$ = this.projectService.currentProject$.pipe(
      tap((project) => {
        this.project = project as Project;
        if (project) {
          this.getAuditLog();
        }
      })
    );
  }

  public onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = 'assets/images/user-default.svg';
    element.classList.add('object-contain');
  }

  public onChangePeriod(): void {
    this.getAuditLog();
  }

  public onExport(): void {
    const exportFields = ['Date & Time', 'Field', 'From', 'To', 'User'];

    const fieldOptions: { label: string; value: string; checked: boolean }[] = exportFields.map((field) => ({ label: field, value: field, checked: true }));
    this.dialogService
      .open(ExportDataComponent, {
        header: 'Export Activity Log',
        dismissableMask: true,
        data: {
          projectId: this.projectService.currentProject.ID,
          action: `export-${this.projectService.currentProject.Slug}-activity-log`,
          fieldOptions,
          project: this.projectService.currentProject
        }
      })
      .onClose.pipe(take(1))
      .subscribe((fields) => {
        if (fields && fields.length > 0) {
          this.exportLog(fields);
        }
      });
  }

  private exportLog(selectedColumns: string[]): void {
    const data: any[] = [];
    const columnMap = this.getColumnMap();
    this.auditLogTableData.forEach((log: AuditTableData) => {
      const rowData: any = {};

      selectedColumns.forEach((column) => {
        if (columnMap[column]) {
          rowData[column] = columnMap[column](log);
        }
      });

      data.push(Object.values(rowData));
    });
    exportToCSV(data, `${this.projectService.currentProject.Slug}-activity-log`, selectedColumns);
  }

  private getAuditLog(): void {
    const params = new HttpParams({ fromObject: { period: this.selectedFilter } });
    // No need to pass query param when filter type All Time.
    this.auditLogs$ = this.projectService.getAuditLog(this.project.ID, this.selectedFilter === 'All Time' ? undefined : params).pipe(
      catchError((error) => {
        this.hasError = true;
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get audit log. Please try again or contact support.',
          data: error,
          key: 'support'
        });

        return of();
      }),
      tap((paginatedAuditLog: PaginatedResponse<AuditLog[]>) => {
        this.hasError = false;
        this.processTableData(paginatedAuditLog.Data || []);
      })
    );
  }

  private processTableData(auditLogs: AuditLog[] = []): void {
    this.auditLogTableData = []; // Reset it for search and filter cases.
    this.filterUsers = []; // Reset users filter
    auditLogs.map((log: AuditLog) => {
      log.AuditUser.Name = log.AuditUser.Name || log.AuditUser.UserName || '-';
      log.Changes.forEach((change) => {
        if (!this.hideFields.includes(change.Field)) {
          const from = change.From?.trim() || '-';
          const to = change.To?.trim() || '-';
          // Convert date to human readable form
          const obj = {
            createdAt: log.CreatedAt,
            filterDate: new Date(log.CreatedAt),
            field: this.getFieldName(change.Field) || '-',
            from: this.formatData(from),
            to: this.formatData(to),
            auditUser: log.AuditUser
          };
          this.auditLogTableData.push(obj);
          this.filterUsers.push(log.AuditUser);
        }
      });
    });
    this.filterUsers = uniqBy(this.filterUsers, (obj) => obj.UserID);
  }

  private getFieldName(field: string): string {
    return this.activityLogMapping.find((option) => option.field === field)?.value || field.trim();
  }

  private formatData(data: string): string {
    // Required to covert some string into human readable format.
    if (data === 'nil') {
      return `-`;
    }

    if (this.isValidURL(data)) {
      // Convert to a link.
      return `<a class="text-primary" href="${data}" target="_blank">${data}</a>`;
    }

    return data;
  }

  private isValidURL(url: string): boolean {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/\S*)?$/;
    return urlPattern.test(url);
  }

  private getColumnMap(): { [key: string]: (log: AuditTableData) => any } {
    return {
      ['Date & Time']: (log) => this.datePipe.transform(log.createdAt, 'dd MMM y h:mm a') || '-',
      Field: (log) => log.field || '-',
      From: (log) => log.from || '-',
      To: (log) => log.to || '-',
      User: (log) => log.auditUser.Name + '(' + log.auditUser.Email + ')'
    };
  }
}
