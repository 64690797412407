<div *ngIf="project$ | async; else loading" class="activity-log-container">
  <div class="flex justify-between mb-6">
    <div class="text-lg font-bold">{{ project.Name || '' }}</div>
    <div class="flex justify-end gap-4 items-center">
      <p-dropdown [options]="recordShowOptions" [(ngModel)]="selectedFilter" appendTo="body" (onChange)="onChangePeriod()"></p-dropdown>
      <i
        *ngIf="!hasError && auditLogTableData.length > 0"
        title="Download activity Log"
        class="fa-regular fa-arrow-down-to-line text-xl cursor-pointer"
        (click)="onExport()"></i>
    </div>
  </div>

  <div *ngIf="auditLogs$ | async; else auditLoading" class="grid gap-6">
    <div class="overflow-auto" [ngStyle]="{ height: tableHeight }">
      <p-table
        [value]="auditLogTableData"
        rowGroupMode="rowspan"
        groupRowsBy="createdAt"
        sortField="createdAt"
        [sortOrder]="-1"
        sortMode="single"
        [paginator]="true"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [rows]="5">
        <ng-template pTemplate="header">
          <tr>
            <th>
              Date & Time
              <p-columnFilter
                type="date"
                field="filterDate"
                display="menu"
                [showMatchModes]="true"
                appendTo="body"
                [showOperator]="false"
                [showAddButton]="false">
              </p-columnFilter>
            </th>
            <th>
              Field
              <p-columnFilter type="text" field="field" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
            </th>
            <th>
              From
              <p-columnFilter type="text" field="from" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
            </th>
            <th>
              To
              <p-columnFilter type="text" field="to" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
            </th>
            <th>
              User
              <p-columnFilter field="auditUser" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="filterUsers" placeholder="Search User" (onChange)="filter($event.value)" optionLabel="Name">
                    <ng-template let-option pTemplate="item">
                      <div class="flex gap-2 items-center">
                        <p-avatar
                          *ngIf="option.Avatar && option.Avatar !== ''"
                          [image]="option.Avatar"
                          (onImageError)="onImageError($event)"
                          shape="circle"></p-avatar>
                        <p-avatar *ngIf="!option.Avatar || option.Avatar === ''" [label]="option.Name | initial" shape="circle"></p-avatar>
                        <div class="text-xs">{{ option.Name }}</div>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-auditLog let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
          <tr>
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
              <!-- Added fixed width to date as it is shrink when from and to data having long chars without space (link,URL)-->
              <div>{{ auditLog.createdAt | date: 'dd MMM y h:mm a' }}</div>
            </td>
            <td>{{ auditLog.field }}</td>
            <td>
              <!-- Added txt-ellipse class to show only limited length of chars string if it is without space else show all the text data-->
              <div class="txt-ellipsis" [innerHTML]="auditLog.from"></div>
            </td>
            <td>
              <div class="txt-ellipsis" [innerHTML]="auditLog.to"></div>
            </td>
            <td>
              <ng-container [ngTemplateOutlet]="memberInfo" [ngTemplateOutletContext]="{ auditUser: auditLog.auditUser }"></ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">
              <div class="text-center">This project has no activity log</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #memberInfo let-row="auditUser">
  <div class="flex flex-row justify-start items-center gap-2">
    <p-avatar
      *ngIf="row.Avatar && row.Avatar !== ''"
      [title]="row.UserName || '-'"
      [image]="row.Avatar"
      styleClass="cursor-pointer"
      (onImageError)="onImageError($event)"
      shape="circle"></p-avatar>
    <p-avatar
      [title]="row.UserName || '-'"
      *ngIf="!row.Avatar || row.Avatar === ''"
      [label]="row.FirstName | initial"
      shape="circle"
      styleClass="cursor-pointer"></p-avatar>
    <div>
      <div class="user" [ngClass]="{ 'no-user': row.Name === '-' }">{{ row.Name }}</div>
      <div [title]="row.Email || '-'" class="truncate w-44">{{ row.Email }}</div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="activity-log-container">
    <div class="flex justify-between">
      <p-skeleton width="180px" height="25px"></p-skeleton>
    </div>
    <ng-container [ngTemplateOutlet]="auditLoading"></ng-container>
  </div>
</ng-template>

<ng-template #auditLoading>
  <div *ngIf="hasError" class="text-sm text-red text-center">Failed to retrieve audit log</div>
  <ng-container *ngIf="!hasError">
    <p-table
      [value]="[1, 2]"
      rowGroupMode="rowspan"
      groupRowsBy="createdAt"
      sortField="createdAt"
      sortMode="single"
      [scrollable]="true"
      [scrollHeight]="tableHeight"
      breakpoint="1024px">
      <ng-template pTemplate="header">
        <tr style="width: 15%">
          <th>Date & Time</th>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
          <th>User</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>
          <td><p-skeleton height="15px"></p-skeleton></td>
          <td>
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <div class="grid gap-2">
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
            </div>
          </td>
          <td>
            <div class="grid gap-2">
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
            </div>
          </td>
          <td>
            <ng-container [ngTemplateOutlet]="memberLoading"></ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</ng-template>

<ng-template #memberLoading>
  <div class="flex flex-row justify-start items-center gap-2">
    <p-skeleton shape="circle" size="2rem"></p-skeleton>
    <div class="grid gap-2">
      <p-skeleton width="150px" height="15px"></p-skeleton>
      <p-skeleton width="150px" height="15px"></p-skeleton>
    </div>
  </div>
</ng-template>
